<template>
  <v-footer
    :fixed="isFixed"
    padless
  >
  <v-card
    flat
    tile
    class="text-center coreview" width="100%">
    <v-img
      :src="require('@/assets/Logo_NECTEC-2020.png')"
      class="my-0"
      contain
      height="50"
    />
  <v-divider></v-divider>
  <v-card-text class="py-1 white--text overline">
    {{ new Date().getFullYear() }} — <strong> NECTEC</strong>
    <!-- {{ $vuetify.breakpoint.width }} {{ $vuetify.breakpoint.height }} {{ $vuetify.breakpoint.smAndUp }} {{ $vuetify.breakpoint.name }} -->
  </v-card-text>
</v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'CoreFooter',
  props: ['isFixed'],
  components: {
    // SocialMedia: () => import('@/components/SocialMedia')
  },
  computed: {
    height: () => {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220
        case 'sm': return 400
        case 'md': return 500
        case 'lg': return 600
        case 'xl': return 800
      }
    }
  }
}
</script>
